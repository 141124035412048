<style>
    .item-deleted {
        background-color: #ffaeae;
    }
    .date-row {
        cursor: pointer;
    }
</style>

<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="Chọn ngày"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @input="menu = false" no-title scrollable>
                                <!-- <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="get_link_orders(date)">OK</v-btn> -->
                            </v-date-picker>
                        </v-menu>
                    </div>

                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items="link_orders"
                        :loading="loading"
                        class="elevation-1"

                        :items-per-page="15"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        
                                    </div>
                                    <div class="col-md-4">
                                        
                                    </div>
                                </div>
                            </template>

                            <template v-slot:item.picture="{ item }">
                                <img :src="item.picture" style="width:50px;height:50px;" />
                            </template>
                            <!-- <template v-slot:item.name="{ item }">
                                <a :href="'https://facebook.com/' + item.fb_id" target="_blank">{{ item.name }}</a>
                            </template> -->

                            
                            <!-- <template v-slot:item.Item_id="{ item }">
                                {{ item.Item_id ? item.Item_id.Name : "" }}
                            </template>
                            <template v-slot:item.Status="{ item }">
                                {{ status_to_text(item.Status) }}
                            </template>
                            <template v-slot:item.User_id="{ item }">
                                {{ item.User_id ? (item.User_id.Name ? item.User_id.Name : item.User_id.User_name) : "" }}
                            </template>
                            <template v-slot:item.Amount="{ item }">
                                {{ item.Amount.toLocaleString("vi-VN", {style: "currency", currency: "VND"}) }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <router-link :to="`/orders/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link>
                                <v-icon small @click="confirm_delete(item)" >
                                    mdi-delete
                                </v-icon>
                            </template> -->
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Chọn ngày'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: this.$route.query.q || "",
            status: this.$route.status || 0,
            headers: [
                { text: "Code", align: 'start', sortable: false, value: 'code'},
                { text: "uid", value: 'uid', sortable: false },
                { text: "FB ID", value: 'fb_id', sortable: false },
                { text: "Hình ảnh", value: 'picture', sortable: false },
                { text: "Tên", value: 'name', sortable: false },
                { text: "Comment", value: 'comment', sortable: false },
                { text: "Number Order", value: 'number_order', sortable: false },
                { text: "SĐT", value: 'phone', sortable: false },
                { text: "Confirm", value: 'confirm', sortable: false },
                { text: "Tên Fanpage", value: 'nameFanPage', sortable: false },
                { text: "Video ID", value: 'video_id', sortable: false },
            ],
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            menu2: false,
        };
    },
    watch: {
        date: {
            handler() {
                this.get_link_orders(this.date);
            },
            deep: true
        },
    },
    computed: {
        // dateFormatted() {
        //     return this.formatDate(new Date().toISOString().substr(0, 10));
        // },
        link_orders() {
            return this.$store.getters['orders/get_link_orders'];
        },
        dates() {
            var arr = [];
            for(var i = 0; i < 100; i++) {
                var tmp_date = new Date();
                tmp_date.setDate(tmp_date.getDate() - i);

                var tmp_start = new Date();
                tmp_start.setDate(tmp_start.getDate() - i);
                tmp_start.setHours(0);
                tmp_start.setMinutes(0);
                tmp_start.setSeconds(0);

                var tmp_end = new Date();
                tmp_end.setDate(tmp_end.getDate() - i);
                tmp_end.setHours(23);
                tmp_end.setMinutes(59);
                tmp_end.setSeconds(59);

                arr.push({
                    date_str: tmp_date.toLocaleDateString("vi-VN"),
                    date_start: Math.floor(tmp_start.getTime() / 1000),
                    date_end: Math.floor(tmp_end.getTime() / 1000),
                });
            }
            return arr;
        }
    },
    components: {
    },
    methods: {
        handle_click(row) {
            // console.log("row", row.date_start);
            // console.log("row2", row.date_end);
            if(!row) return;
            if(!row.date_start || !row.date_end) return;

            this.$router.push({
                name: "orders-index",
                query: { page: 1, limit: 15, q: "", status: "", start: row.date_start, end: row.date_end }
            }).catch(() => { });
        },
        class_date_row: function () {
            return "date-row";
        },
        get_link_orders(date) {
            console.log("date", date);
            var self = this;
            this.loading = true;
            this.$store.dispatch('orders/view_link_orders', { date }).then(() => {
                self.loading = false;
            }).catch(e => {
                self.loading = false;
                console.log(e);
            });
        }
    },
    mounted() {
        // this.$store.dispatch(SET_BREADCRUMB, [ { title: "Đơn hàng", route: "index" }, { title: "Tất cả" } ]);
        // this.$store.dispatch('orders/view_link_orders', payload);
    }
};
</script>

